import React, { useMemo } from 'react';
import { useWebcomponent } from '@hpx-core-experiences/react-my-account-commons/dist/index';
import useFeatureFlags from 'src/hooks/useFeatureFlags';
import { FeatureFlagsEnum } from 'src/utils/enums';
import { getQualtricsSurveyUrl } from 'src/utils/urls';
import { Container } from './styles';
import '../styles/globals.scss';
import {
  DependencyManagerProvider,
  useDependencyManagerContext
} from 'src/contexts/dependencyManager';
import * as T from './types';
import { RouteView } from 'src/router';

const AppComponent: React.FC<T.AppPropsType> = () => {
  const { northboundAPIs } = useDependencyManagerContext();

  const client = useMemo(
    () => northboundAPIs.v1.graphql.getClient(),
    [northboundAPIs.v1.graphql]
  );

  const GraphQLProvider = useMemo(
    () => northboundAPIs.v1.graphql.reactTools.createGraphQLProvider(React),
    [northboundAPIs.v1.graphql.reactTools]
  );

  const {
    featureFlagValue: qualtricsSurvey,
    isLoading: isQualtricsSurveyLoading
  } = useFeatureFlags(FeatureFlagsEnum.qualtricsSurvey);

  const showQualtricsSurvey = qualtricsSurvey && !isQualtricsSurveyLoading;

  useWebcomponent({
    url: showQualtricsSurvey ? getQualtricsSurveyUrl() : '',
    scriptType: 'text/javascript',
    scriptId: 'qualtrics-survey',
    target: 'head'
  });

  return (
    <GraphQLProvider client={client}>
      <Container data-testid="subscriptions-page">
        <RouteView />
      </Container>
    </GraphQLProvider>
  );
};

const MemoizedAppComponent = React.memo(AppComponent);

const App: React.FC<T.AppPropsType> = () => {
  return (
    <DependencyManagerProvider>
      <MemoizedAppComponent />
    </DependencyManagerProvider>
  );
};

export default App;
