import { Card } from '@veneer/core';
import tokens from '@veneer/tokens';
import styled from 'styled-components';

const breakpoints = {
  mininum: '375px' // Mobile (iPhone) breakpoint from Figma
};

export const MainTheme = {
  mediaQueries: {
    mobile: `(max-width: ${breakpoints.mininum})`,
    extraSmall: `(max-width: ${tokens.layout.smMin})`,
    small: `(min-width: ${tokens.layout.smMin}) and (max-width: ${tokens.layout.smMax})`,
    medium: `(min-width: ${tokens.layout.mdMin}) and (max-width: ${tokens.layout.mdMax})`,
    large: `(min-width: ${tokens.layout.lgMin} and (max-width: ${tokens.layout.lgMax})`,
    largeScreen: `(min-width: ${tokens.layout.lgMin})`
  }
};

export const Section = styled(Card)`
  display: flex;
  padding: ${tokens.layout.size4};
  flex-direction: column;
  gap: ${tokens.layout.size2};

  && {
    border-radius: ${tokens.layout.size4};
  }
`;

export const CardContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: flex-start;
  padding-top: ${tokens.layout.size2};
  @media screen and (max-width: ${tokens.layout.smMin}) {
    grid-template-columns: 1fr;
    gap: ${tokens.layout.size2};
  }
`;

export const RowIcon = styled.div`
  display: flex;
  align-items: center;
  gap: ${tokens.layout.size2};
  width: 100%;
  & > .logo-instant-ink > img {
    height: 15px;
    width: 80px;
  }
  & > .custom-image > img {
    height: 55px;
    width: 72px;
  }

  > span {
    width: 100%;
  }
`;

export const Label = styled.div`
  color: ${tokens.color.gray6};
  font-size: ${tokens.typography.size4};
`;

export const Link = styled.a`
  color: ${tokens.color.hpBlue6};
`;

export const Row = styled.div<{ $flexDirection: 'column' | 'row' }>`
  display: flex;
  flex-direction: ${({ $flexDirection }) => $flexDirection};
`;

export const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 72px;
`;

export const History = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${tokens.layout.size2};
  align-self: end;
`;

export const HistoryContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${tokens.layout.size2};

  b {
    font-size: 16px;
  }
`;

export const Gap8 = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${tokens.layout.size2};
`;

export const EmptyContent = styled.div`
  padding-top: ${tokens.layout.size2};
`;

export const Notification = styled.div`
  @media ${MainTheme.mediaQueries.mobile} {
    position: relative;

    && div {
      display: grid;
    }

    && svg {
      position: absolute;
      width: ${tokens.layout.size6};
      height: ${tokens.layout.size6};
    }

    && .vn-notification__text {
      display: flex;
      padding-left: ${tokens.layout.size8};
      font-size: ${tokens.typography.size1};
    }

    && button {
      flex-direction: column;
      align-items: flex-end;
      flex-wrap: wrap;
      padding-right: ${tokens.layout.size5};

      span {
        font-size: ${tokens.typography.size1};
      }
    }
  }
`;
