import React, { memo, useMemo, useEffect } from 'react';
import ROUTES from '../routes';
import { useDependencyManagerContext } from 'src/contexts/dependencyManager';

type PageViewProps = {
  pathname: string;
};

const subscriptionDetailsPattern =
  /^subscriptions\/(\d+|[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})$/;

const PageView: React.FC<PageViewProps> = ({ pathname = '' }) => {
  const { northboundAPIs } = useDependencyManagerContext();
  const navigation = northboundAPIs?.v1?.navigation;

  const queryParams = useMemo(() => new URLSearchParams(location.search), []);

  useEffect(() => {
    if (pathname.startsWith('subscriptions')) {
      if (subscriptionDetailsPattern.test(pathname)) {
        const type = queryParams.get('type');
        if (!type) {
          // Remove all query params
          const newSearchParams = new URLSearchParams();
          navigation.replace({
            pathname: '/subscriptions',
            search: newSearchParams.toString()
          });
        }
      } else {
        // Redirect if the ID is not numeric or a valid UUID
        navigation.replace({
          pathname: '/subscriptions',
          search: queryParams.toString()
        });
      }
    }
  }, [navigation, pathname, queryParams]);

  const PageComponent = useMemo(() => {
    if (pathname.startsWith('subscriptions')) {
      if (subscriptionDetailsPattern.test(pathname)) {
        const type = queryParams.get('type');
        if (!type) {
          return ROUTES.subscriptions;
        }
        return ROUTES.subscriptionDetails;
      }
      return ROUTES.subscriptions;
    }
  }, [pathname, queryParams]);

  return <>{PageComponent && <PageComponent />}</>;
};

PageView.displayName = 'PageView';

export default memo(PageView);
