import React, { memo, Suspense } from 'react';
import Fallback from '../Fallback';
import PageView from './PageView';
import { usePathname } from 'src/hooks/usePathname';

const RouteView: React.FC = () => {
  const pathname = usePathname();

  return (
    <Suspense fallback={<Fallback />}>
      <PageView pathname={pathname} />
    </Suspense>
  );
};

RouteView.displayName = 'RouteView';

export default memo(RouteView);
