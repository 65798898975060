import { lazy, LazyExoticComponent } from 'react';

type RoutesType = {
  [key: string]: LazyExoticComponent<() => JSX.Element>;
};

const Subscriptions = lazy(() => import('src/pages/Subscriptions'));
const SubscriptionDetails = lazy(() => import('src/pages/SubscriptionDetails'));

const ROUTES: RoutesType = {
  subscriptions: Subscriptions,
  subscriptionDetails: SubscriptionDetails
};

export default ROUTES;
