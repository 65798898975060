import React, { FC } from 'react';
import styled from 'styled-components';
import { Loading } from '@hpx-core-experiences/react-my-account-commons/dist';

const CenteredDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const Fallback: FC = () => (
  <CenteredDiv>
    <Loading testid="loading-personal-details" />
  </CenteredDiv>
);

export default Fallback;
